window.jQuery = window.$ = require('jquery');
require('bootstrap');
$(document).ready(function(){
    $('#otp-input').each(function(){
        $(this)[0].addEventListener('scroll', function(e){
            $(this)[0].scrollLeft=0;
        });
    });
    $('#resend').each(function(){
        var $this = $(this)
        setTimeout(function(){
            $this.show()
            $('#otp-form').hide()
        }, 1000 * 60 * 5);
    })
    $('#car-size').each(function(){
        $(this).on('keyup',function(e){
            var val = e.target.value;
            var r = '-';
            var size = '';
            if( $.isNumeric(val) && val > 100 && val < 300 ) {
                var hp = val/1.3596
                if(hp < 90) size = 'S';
                else if(hp < 120) size = 'M';
                else size = 'L';
                // r = Math.round(hp);
            } else {
                size = '-';
                // r = '-';
            }
            $('#car-size-result').val(size);
        })
    });
});
